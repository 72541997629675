<!-- Finder particular de Compañías Sucursales -->

<template> 
  <div class="cias_F" v-if="schema">
    <div class="conflex" style="justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera">       
          <base_Header 
            :Entorno="Entorno.header"
            @onEvent="event_Header">          
          </base_Header>
        </div>

        <!-- Contenido -->
        <div class="contenedor" style="width:1050px">       
          <div class="columna">
            <div class="conflex">
              <v-text-field
                style="width:70%"
                v-bind="$input"
                v-model="schema.ctrls.cia.value"
                :label="schema.ctrls.cia.label"
                @keypress.13="buscarExec('buscarCia')">                
              </v-text-field>
              
              <v-btn v-bind="$cfg.btn.busca" @click="buscarExec('buscarCia')">
                <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
              </v-btn>
            </div>

            <div class="conflex">
              <ctrlfinder style="width:50%" finderName="atlas_F" :schema="schema.ctrls.atlas"></ctrlfinder>
              <ctrlfinder style="width:50%" finderName="ciac_F" :schema="schema.ctrls.ciac"></ctrlfinder>
            </div>
          </div>

          <!-- Grid -->
          <base_Fgrid :padre="stName" :Entorno="Entorno" @onEvent="event_Grid">
            <!-- Slot botonera Top -->
            <template v-slot:top_btns="{ Sitem }">         
              <div style="padding:10px 0 0 10px;">
                <btracombi
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="top"
                  @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
                </btracombi>
              </div>
            </template>

            <!-- Slot campo acciones del Grid -->
            <template v-slot:acciones="{ Sitem }">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="mrow"
                @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </template>
          </base_Fgrid>
        </div>   
      </v-sheet>
    
      <!-- Mto como ventana modal -->      
      <v-dialog
        v-model="modal">
          <div class="centrado">             
            <component                 
                :is="component_M"
                :padre="stName"
                :accion="accion"
                :accionRow="accionRow"
                :disparo="disparo"
                @onEvent="event_Mto">
            </component>           
          </div>
      </v-dialog>
    </div>  
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base"); 
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");  
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const cias_M = () => plugs.groute("cias_M.vue", "comp");

  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btracombi, ctrlfinder, cias_M },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema:null,
        api:'cias_F',
        stName:'stFcias',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,
        
        headers:{}
      };
    },
  

    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno 
        this.Entorno.header.titulo = "Compañias Sucursales";        

        this.headers = {
          header:[
            { text: "Acciones", value: "acciones", sortable: false, width: "10%", slot:true },
            { text: "Compañía", value: "name", width: "40%" },
            { text: "Atlas", value: "atlas", width: "40%" },
            { text: "Clase", value: "situ", width: "10%" }
          ]
        };
        
        this.Entorno.grid.headers = this.set_headers();

        // guardo nombre componente de Mto
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'cias_M';
      },
    }
  };
</script>
